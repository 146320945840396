import {  NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MappingSidebarComponent } from './mapping-sidebar.component';

@NgModule({
  imports:      [ CommonModule ],
  declarations: [ MappingSidebarComponent ],
  exports:      [ MappingSidebarComponent ]
})
export class MappingSidebarModule {
}
