<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg">
                    <span class="logo-text secondary-text">CSP Companion</span>
                </div>
            </div>

            <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            

            

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.svg'" style="width:16px; height:10px;">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.svg'" style="width:16px; height:10px;">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

                <button mat-menu-item [routerLink]="'/components/multi-language'">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>Learn more</span>
                    </div>
                </button>

            </mat-menu>

            <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button fxHide.gt-md
                    class="chat-panel-toggle-button"
                    (click)="toggleSidebarOpen('chatPanel')"
                    aria-label="Toggle chat panel">
                <mat-icon class="secondary-text">chat</mat-icon>
            </button>

            <div class="toolbar-separator" fxHide.gt-md></div>

            <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->

            <div class="toolbar-separator"></div>

            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center" *ngIf="auth.user$ | async as user">
                    <img class="avatar mr-0 mr-sm-16"  src="{{user['https://api.msplus.online/profile_picture']}}">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{user['https://api.msplus.online/full_name']}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngIf="auth.isAuthenticated$ | async" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

        </div>

    </div>

</mat-toolbar>
