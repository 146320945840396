import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment } from "environments/environment";
import { ResellerService } from 'app/main/models/resellerservice.model';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Observable } from 'rxjs';
import { ResellerDistributorMappingService } from 'app/reseller-distributor-mapping/reseller-distributor-mapping.service';
import { ManagedServicesStatsService } from 'app/main/content/managed-services/managed-services-stats/managed-services-stats.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesListService {

  private _activatedServices: ResellerService[] = [];
  readonly resellerServicesEndpoint = `${environment.apiUrl}/api/resellerservices`;

  constructor(
    private http: HttpClient,
    private fuseNavigationService: FuseNavigationService,
    private mappingService: ResellerDistributorMappingService,
    private managedServicesStatsService: ManagedServicesStatsService
  ) 
  {  }

  /**
   * Return the full list of services associated with the reseller mapping
   *
   * @returns {ResellerService[]}
   */
  public getServicesList(): Observable<ResellerService[]> {
    return this.http.get<ResellerService[]>(`${this.resellerServicesEndpoint}`);
  }

  isInService(serviceId:string): boolean
  {
    var serviceIndex = this._activatedServices.findIndex(item => item.serviceId == serviceId);

    if (serviceIndex != -1)
      return true;
    else
      return false;
  }

  enableHiddenMenuItems()
  {
     this.mappingService.onMappingsLoaded().subscribe(currentMapping => {
      if (currentMapping && currentMapping[0].resellerPlatform == 'Autotask') {

        this.fuseNavigationService.updateNavigationItem('credentials', {
          hidden:false
        });
      }
      if (currentMapping && currentMapping[0].resellerId.toUpperCase() == 'B1E31313-BCC4-425C-A764-6AB6744B2D80')
      {
        setTimeout(() => {
          this.fuseNavigationService.updateNavigationItem('partnerbilling', {
              hidden: false
              });
        }, 0);
      }
     });
     
      this.http.get<ResellerService[]>(`${this.resellerServicesEndpoint}`)
      .subscribe(
        (serviceList) =>
        {
          this._activatedServices = serviceList;

          /* If in the Office 365 Service or Azure then show the data collectors menu item */
          if (this.isInService('6890b5d9-facb-45a6-b0b3-d02251fcac49') ||
          this.isInService('9ea2f0f6-6bb3-49e9-b57b-f5daece3c2c0'))
          {
            setTimeout(() => {
              this.fuseNavigationService.updateNavigationItem('microsoftcsp', {
                  hidden: false
                  });
            }, 0);
          }

          /* If in the Active Directory Service then show the data collectors menu item */
          if (this.isInService('99af8ddc-323f-4f64-802c-b80259b41e9b'))
          {
            setTimeout(() => {
              this.fuseNavigationService.updateNavigationItem('agentpools', {
                  hidden: false
                  });
              this.fuseNavigationService.updateNavigationItem('managedservices', {
                  hidden: false
                  });
            }, 0);
          }

          /* If in the Managed Services Service then show the data collectors menu item */
          if (this.isInService('516bfc7b-cf44-4fe8-a25f-b5a19c93242a'))
          {
            setTimeout(() => {
              // Pre cache the managed services stats so they are ready when someone clicks into the page
              this.managedServicesStatsService.cacheManagedServicesStats();

              this.fuseNavigationService.updateNavigationItem('managedservicesv2', {
                  hidden: false
                  });

              this.fuseNavigationService.updateNavigationItem('agentpools', {
                    hidden: false
                  });

              this.fuseNavigationService.updateNavigationItem('setupmanagedservices', {
                hidden: false
                });
            }, 0);
          }


          /* If in the manage protect service then show the Services menu item */
          if (this.isInService('bac2d5e4-cd0d-4231-873b-5c6560114f21'))
          {
            setTimeout(() => {
              this.fuseNavigationService.updateNavigationItem('manageprotect', {
                  hidden: false
                  });
            }, 0);
          }
          /* If not csp then hide the items */
          if (!this.isInService('6890b5d9-facb-45a6-b0b3-d02251fcac49'))
          {
            setTimeout(() => {
              // this.fuseNavigationService.updateNavigationItem('dashboard', {
              //     hidden: true
              //     });
              this.fuseNavigationService.updateNavigationItem('customers', {
                hidden: true
                });
              this.fuseNavigationService.updateNavigationItem('alerts', {
                hidden: true
                });
              // this.fuseNavigationService.updateNavigationItem('syncprocessing', {
              //   hidden: true
              //   });
              this.fuseNavigationService.updateNavigationItem('invoicingruns', {
                hidden: true
                });
              this.fuseNavigationService.updateNavigationItem('products', {
                hidden: true
                });
            }, 0);
          }
        }
      );

    
  }
}
