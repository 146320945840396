import { Component, OnInit, OnDestroy, Input, ElementRef, ChangeDetectorRef, Renderer2, ViewContainerRef } from '@angular/core';
import { MappingSidebarService } from './mapping-sidebar.service';

@Component({
  selector: 'mapping-sidebar',
  templateUrl: './mapping-sidebar.component.html',
  styleUrls: ['./mapping-sidebar.component.scss']
})
export class MappingSidebarComponent implements OnInit, OnDestroy {

  // Name
  @Input()
  name: string;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _mappingSidebarService: MappingSidebarService,
    private _renderer: Renderer2,
    public viewContainerRef: ViewContainerRef
  )
  {
    
  }

  // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Register the sidebar
        this._mappingSidebarService.register(this.name, this);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unregister the sidebar
        this._mappingSidebarService.unregister(this.name);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the sidebar
     */
    show(): void
    {
        // Show the sidebar
        this._showSidebar();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Hide the sidebar
     */
    hide(): void
    {
        // Show the sidebar
        this._hideSidebar();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Clear and Hide the sidebar
     */
    clearAndHide(): void
    {
        console.log(this.viewContainerRef);
        this.viewContainerRef.clear();
        // Show the sidebar
        this._hideSidebar();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Change some properties of the sidebar
     * and make it visible
     *
     * @private
     */
    private _showSidebar(): void
    {
        // Remove the box-shadow style
        this._renderer.removeStyle(this._elementRef.nativeElement, 'box-shadow');

        // Make the sidebar invisible
        this._renderer.removeStyle(this._elementRef.nativeElement, 'visibility');

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Change some properties of the sidebar
     * and make it invisible
     *
     * @private
     */
    private _hideSidebar(delay = true): void
    {
        const delayAmount = delay ? 300 : 0;

        // Add a delay so close animation can play
        setTimeout(() => {

            // Remove the box-shadow
            this._renderer.setStyle(this._elementRef.nativeElement, 'box-shadow', 'none');

            // Make the sidebar invisible
            this._renderer.setStyle(this._elementRef.nativeElement, 'visibility', 'hidden');
        }, delayAmount);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }


}
