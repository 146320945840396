export const locale = {
    lang: 'fr',
    data: {
        'NAV': {
            'HOME': 'Maison',
            'BILLING': 'Billing',
            'DASHBOARD'        : {
                'TITLE': 'Tableau de Bord',
                'BADGE': '15'
            },
            'CUSTOMERS'        : {
                'TITLE': 'Les Clients',
                'BADGE': '15'
            },
            'CUSTOMERMAPPINGS'        : {
                'TITLE': 'Mappages de clients',
                'BADGE': '15'
            },
            'ALERTS'        : {
                'TITLE': 'Alertes',
                'BADGE': '0'
            },
            'SYNCPROCESSING'        : {
                'TITLE': 'Traitement de Synchronisation',
                'BADGE': '25'
            },
            'PROVISIONING'        : {
                'TITLE': 'Approvisionnement'
            },
            'INVOICINGRUNS'        : {
                'TITLE': 'Transactions de facturation'
            },
            'PRODUCTS'        : {
                'TITLE': 'Produits'
            },
            'DATACOLLECTORS'        : {
                'TITLE': 'Collecteurs de données'
            },
            'AGENTPOOLS'        : {
                'TITLE': 'Pools d\'Agents'
            },
            'SERVICES'        : {
                'TITLE': 'Services'
            },
            'MICROSOFTCSP'        : {
                'TITLE': 'Microsoft CSP'
            },
            'SETTINGS'        : {
                'TITLE': 'Settings'
            },
            'ADMIN': 'Administrateur'
        }
    }
};
