import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ServicesListService } from './services-list.service';



@NgModule()
export class ServicesListModule {

  constructor (@Optional() @SkipSelf() parentModule: ServicesListModule) {
    if (parentModule) {
      throw new Error(
        'ServicesListModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<ServicesListModule> {
    return {
      ngModule: ServicesListModule,
      providers: [ ServicesListService ]
    }
  }

}
