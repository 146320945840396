
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ResellerDistributorMappingService } from './reseller-distributor-mapping.service';

@NgModule()
export class ResellerDistributorMappingModule {

  constructor (@Optional() @SkipSelf() parentModule: ResellerDistributorMappingModule) {
    if (parentModule) {
      throw new Error(
        'ResellerDistributorMappingModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<ResellerDistributorMappingModule> {
    return {
      ngModule: ResellerDistributorMappingModule,
      providers: [ ResellerDistributorMappingService ]
    }
  }

}

