export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'HOME': 'Home',
            'BILLING': 'Billing',
            'SETUP': 'Setup',
            'DASHBOARD'        : {
                'TITLE': 'Dashboard',
                'BADGE': '25'
            },
            'CUSTOMERS'        : {
                'TITLE': 'Customers',
                'BADGE': '25'
            },
            'CUSTOMERMAPPINGS'        : {
                'TITLE': 'Customer Mappings',
                'BADGE': '25'
            },
            'ALERTS'        : {
                'TITLE': 'Alerts',
                'BADGE': '0'
            },
            'SYNCPROCESSING'        : {
                'TITLE': 'Sync Processing',
                'BADGE': '25'
            },
            'PROVISIONING'        : {
                'TITLE': 'Provisioning'
            },
            'INVOICINGRUNS'        : {
                'TITLE': 'Invoicing Runs'
            },
            'PRODUCTS'        : {
                'TITLE': 'Products'
            },
            'DATACOLLECTORS'        : {
                'TITLE': 'Data Collectors'
            },
            'AGENTPOOLS'        : {
                'TITLE': 'Agent Pools'
            },
            'SERVICES'        : {
                'TITLE': 'Services'
            },
            'MICROSOFTCSP'        : {
                'TITLE': 'Microsoft CSP'
            },
            'MANAGEPROTECT'        : {
                'TITLE': 'Manage Protect'
            },
            'MANAGEDSERVICES'        : {
                'TITLE': 'Managed Services'
            },
            'MANAGEDSERVICESV2'        : {
                'TITLE': 'Managed Services v2'
            },
            'SETUPCREDENTIALS'        : {
                'TITLE': 'Credentials'
            },
            'SETUPMANAGEDSERVICES'        : {
                'TITLE': 'Managed Services'
            },
            'PARTNERBILLING'        : {
                'TITLE': 'Partner Billing'
            },
            'ADMIN': 'Admin'
        }
    }
};
