import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MappingSidebarService } from './mapping-sidebar.service';


@NgModule()
export class MappingSidebarServiceModule {

  constructor (@Optional() @SkipSelf() parentModule: MappingSidebarServiceModule) {
    if (parentModule) {
      throw new Error(
        'MappingSidebarServiceModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<MappingSidebarServiceModule> {
    return {
      ngModule: MappingSidebarServiceModule,
      providers: [ MappingSidebarService ]
    }
  }

}
