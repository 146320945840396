import { Injectable, EventEmitter } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment } from "environments/environment";
import { DistributorMapping } from 'app/main/models/distributormapping.model';
import { BehaviorSubject, Observable } from 'rxjs';
import {  skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResellerDistributorMappingService {

  private _resellerDistributorMappings: DistributorMapping[] = [];
  private _currentMapping: DistributorMapping;
  readonly distributorMappingsEndpoint = `${environment.apiUrl}/api/distributormappings`;
  private _onMappingsLoaded:BehaviorSubject<DistributorMapping[]>  = new BehaviorSubject<DistributorMapping[]>(null);

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Return the full list of reseller distributor mappings associated with the current user
   *
   * @returns {DistributorMapping[]}
   */
  getServicesList(): DistributorMapping[]
  {
    // Check if there are any activated services
    if ( !this._resellerDistributorMappings )
    {
        console.warn(`There are no reseller distributor mappings associated with this user.`);
        return;
    }

    // Return the list
    return this._resellerDistributorMappings;
  }

  /**
   * Returns the current reseller distributor mapping in use
   *
   * @returns {DistributorMapping}
   */
  getCurrentMapping(): DistributorMapping
  {
    // Check if there are any activated services
    if ( !this._currentMapping )
    {
        console.warn(`There are no current reseller distributor mapping set.`);
        return;
    }

    // Return the mapping
    return this._currentMapping;
  }

  public getDistributorMappings() {
     this.http.get<DistributorMapping[]>(this.distributorMappingsEndpoint)
        .subscribe(
          (distributorMappings) => {
            this._resellerDistributorMappings = distributorMappings;
            this._currentMapping = distributorMappings[0];
            this._onMappingsLoaded.next(distributorMappings);
          }
        );
  }

  public onMappingsLoaded() {
    return this._onMappingsLoaded.pipe(
      skipWhile((result) => result == null)
    );
  }
}
