import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
/* import { InMemoryWebApiModule } from 'angular-in-memory-web-api'; */
import { TranslateModule } from '@ngx-translate/core';

import { environment } from "environments/environment";

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Import the module from the SDK
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';

import { ToastrModule } from 'ngx-toastr';

// Import the Application Insights module and the service provider
import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';

/* import { FakeDbService } from 'app/fake-db/fake-db.service'; */
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { AlertsService } from './main/content/alerts/alerts.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MappingSidebarServiceModule } from './main/content/mapping-sidebar/mapping-sidebar.service.module';
import { ServicesListModule } from './services-list/services-list.module';
import { ResellerDistributorMappingModule } from './reseller-distributor-mapping/reseller-distributor-mapping.module';
import { ManagedServicesStatsModule } from './main/content/managed-services/managed-services-stats/managed-services-stats.module';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';

const appRoutes: Routes = [
    {
        path        : 'dashboard',
        loadChildren: () => import('./main/content/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path        : 'services',
        loadChildren: () => import('./main/content/services/services.module').then(m => m.ServicesModule)
    },
    {
        path        : 'microsoftcsp',
        loadChildren: () => import('./main/content/microsoft-csp/microsoft-csp.module').then(m => m.MicrosoftCspModule)
    },
    {
        path        : 'alerts',
        loadChildren: () => import('./main/content/alerts/alerts.module').then(m => m.AlertsModule)
    },
    {
        path        : 'invoicing-runs',
        loadChildren: () => import('./main/content/invoicing-runs/invoicing-runs.module').then(m => m.InvoicingRunsModule)
    },
    {
        path        : 'invoicing-runs/:id',
        loadChildren: () => import('./main/content/invoicing-runs/invoicing-runs.module').then(m => m.InvoicingRunsModule)
    },
    {
        path        : 'invoicing-runs/new',
        loadChildren: () => import('./main/content/invoicing-runs/invoicing-runs.module').then(m => m.InvoicingRunsModule)
    },
    {
        path        : 'provisioning',
        loadChildren: () => import('./main/content/provisioning/provisioning.module').then(m => m.ProvisioningModule)
    },
    {
        path        : 'products',
        loadChildren: () => import('./main/content/products/products.module').then(m => m.ProductsModule)
    },
    {
        path        : 'agentpools',
        loadChildren: () => import('./main/content/agent-pools/agent-pools.module').then(m => m.AgentPoolsModule)
    },
    {
        path        : 'setup',
        loadChildren: () => import('./main/content/setup/setup.module').then(m => m.SetupModule)
    },
    {
        path        : 'datacollectors',
        loadChildren: () => import('./main/content/data-collectors/data-collectors.module').then(m => m.DataCollectorsModule)
    },
    {
        path        : 'managedservices',
        loadChildren: () => import('./main/content/managed-services/managed-services.module').then(m => m.ManagedServicesModule)
    },
    {
        path        : 'admin',
        loadChildren: () => import('./main/content/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path        : 'documentation',
        loadChildren: () => import('./main/documentation/documentation.module').then(m => m.DocumentationModule)
    },
    {
        path        : '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        //RouterModule.forRoot(appRoutes,{ enableTracing: true }),

        ApplicationInsightsModule.forRoot({
            instrumentationKey: '08e22576-0e74-4a1e-998e-7f466653c6e3'
          }),

        TranslateModule.forRoot(),
        /* InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }), */
        

        ToastrModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        AuthModule.forRoot({
            domain: 'auth.msplus.online',
            clientId: 'TfHnefNx45whzdBsjTH0w1n30AVs91c3',
            audience: `https://api.msplus.online`,
            redirectUri: window.location.origin,
            httpInterceptor: {
                allowedList: [`${environment.apiUrl}/*`],
              },
          }),

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,
        MappingSidebarServiceModule.forRoot(),
        ServicesListModule.forRoot(),
        ResellerDistributorMappingModule.forRoot(),
        ManagedServicesStatsModule.forRoot(),
        EffectsModule.forRoot([AppEffects])

    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        AppInsightsService,
        AlertsService
      ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
