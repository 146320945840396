import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notification } from '../../models/notification.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';

@Injectable()
export class AlertsService
{
    readonly notificationsEndpoint = `${environment.apiUrl}/api/notifications`;

    constructor(private http: HttpClient, private toastr:ToastrService)
    {
    }

    public getOpenNotifications(): Observable<Notification[]> {
        return this.http.get<Notification[]>(`${this.notificationsEndpoint}`);
    }

    public ignoreNotification(notificationId:string) {
        this.http.post(`${this.notificationsEndpoint}/${notificationId}/Ignore`,{})
            .subscribe(
                res => {
                this.toastr.success('Successfully set the notification to ignore status','Ignore Notification');
                
                },
                err => {
                console.log('Error occured calling Notifications api');
                console.log(err);


                this.toastr.error('Error setting the notification to ignore status','Ignore Notification',{disableTimeOut:true});
                }
            );
    }

    public resolveNotification(notificationId:string) {
        this.http.post(`${this.notificationsEndpoint}/${notificationId}/Resolve`,{})
            .subscribe(
                res => {
                //this.toastr.success('Successfully set the notification to resolve status','Resolve Notification');
                
                },
                err => {
                console.log('Error occured calling Notifications api');
                console.log(err);


                this.toastr.error('Error setting the notification to resolve status','Resolve Notification',{disableTimeOut:true});
                }
            );
    }
    
}
