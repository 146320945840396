import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ManagedServicesStatsService } from './managed-services-stats.service';



@NgModule()
export class ManagedServicesStatsModule {

  constructor (@Optional() @SkipSelf() parentModule: ManagedServicesStatsModule) {
    if (parentModule) {
      throw new Error(
        'ManagedServicesStatsModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<ManagedServicesStatsModule> {
    return {
      ngModule: ManagedServicesStatsModule,
      providers: [ ManagedServicesStatsService ]
    }
  }

}
