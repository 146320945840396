import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment } from "environments/environment";
import { BehaviorSubject } from 'rxjs';
import { ManagedServicesStats } from 'app/main/models/managed-services-stats.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ManagedServicesStatsService {

  private _managedServicesStats: ManagedServicesStats;
  private _onManagedServicesStatsLoaded:BehaviorSubject<ManagedServicesStats> = new BehaviorSubject<ManagedServicesStats>(null);
  readonly managedServicesEndpoint = `${environment.apiUrl}/api/managedservices`;

  constructor(
    private http: HttpClient
  ) 
  {  }

  /**
   * Cache the list of Managed Services stats for this reseller mapping
   */
  public cacheManagedServicesStats()
  {
    this.http.get<ManagedServicesStats>(`${this.managedServicesEndpoint}/stats`).subscribe(stats => {
      this._managedServicesStats = stats;
      // try to work out how many months in are you based on the end date
      if (this._managedServicesStats.trialEndDate != null) {
        var trialEndDate = moment.utc(this._managedServicesStats.trialEndDate);
        var trialStartDate = moment.utc(this._managedServicesStats.trialEndDate).subtract(2, 'months');
        var secondMonthOfTrialStartDate = moment.utc(this._managedServicesStats.trialEndDate).subtract(1, 'months');
        if (moment().isAfter(trialEndDate)) {
          // trial expired
          this._managedServicesStats.trialMonth = -1;
        }
        else if (moment().isSameOrAfter(trialStartDate) && moment().isBefore(secondMonthOfTrialStartDate)) {
          this._managedServicesStats.trialMonth = 1;
        }
        else if (moment().isSameOrAfter(secondMonthOfTrialStartDate) && moment().isBefore(trialEndDate)) {
          this._managedServicesStats.trialMonth = 2;
        }
      }
      this._onManagedServicesStatsLoaded.next(this._managedServicesStats);
    });
  }

  onManagedServicesStatsLoaded() {
    return this._onManagedServicesStatsLoaded.asObservable();
  }

}
