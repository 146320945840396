import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {

        id       : 'billing',
        title    : 'Billing',
        translate: 'NAV.BILLING',
        type     : 'group',
        icon     : 'receipt',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                translate: 'NAV.DASHBOARD.TITLE',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/dashboard'  
            },
            {
                id       : 'services',
                title    : 'Services',
                translate: 'NAV.SERVICES.TITLE',
                type     : 'item',
                icon     : 'list_alt',
                url      : '/services',
                hidden   : true
            },
            {
                id       : 'microsoftcsp',
                title    : 'Microsoft CSP',
                translate: 'NAV.MICROSOFTCSP.TITLE',
                type     : 'item',
                icon     : 'list_alt',
                url      : '/microsoftcsp',
                hidden   : true
            },
            {
                id       : 'managedservices',
                title    : 'Managed Services',
                translate: 'NAV.MANAGEDSERVICES.TITLE',
                type     : 'item',
                icon     : 'list_alt',
                url      : '/managedservices/v1',
                hidden   : true
            },
            {
                id       : 'managedservicesv2',
                title    : 'Managed Services v2',
                translate: 'NAV.MANAGEDSERVICESV2.TITLE',
                type     : 'item',
                icon     : 'list_alt',
                url      : '/managedservices/v2',
                hidden   : true
            },
            {
                id       : 'manageprotect',
                title    : 'Manage Protect',
                translate: 'NAV.MANAGEPROTECT.TITLE',
                type     : 'item',
                icon     : 'list_alt',
                url      : '/services/manage-protect',
                hidden   : true
            },
            {
                id       : 'customers',
                title    : 'Customers',
                translate: 'NAV.CUSTOMERS.TITLE',
                type     : 'item',
                icon     : 'business',
                url      : '/microsoftcsp/customers',
                hidden   : true 
            },
            {
                id       : 'alerts',
                title    : 'Alerts',
                translate: 'NAV.ALERTS.TITLE',
                type     : 'item',
                icon     : 'notifications',
                url      : '/alerts',
                badge    : {
                    title    : '0',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                },
                hidden   : true
            },
            {
                id       : 'syncprocessing',
                title    : 'Sync Processing',
                translate: 'NAV.SYNCPROCESSING.TITLE',
                type     : 'item',
                icon     : 'sync',
                url      : '/syncprocessing',
                hidden   : true
            },
            {
                id       : 'invoicingruns',
                title    : 'Invoicing Runs',
                translate: 'NAV.INVOICINGRUNS.TITLE',
                type     : 'item',
                icon     : 'receipt',
                url      : '/invoicing-runs',
                hidden   : true
            },
            {
                id       : 'products',
                title    : 'Products',
                translate: 'NAV.PRODUCTS.TITLE',
                type     : 'item',
                icon     : 'shopping_cart',
                url      : '/products',
                hidden   : true
            },
            {
                id       : 'partnerbilling',
                title    : 'Partner Billing',
                translate: 'NAV.PARTNERBILLING.TITLE',
                type     : 'item',
                icon     : 'list_alt',
                url      : '/admin/partnerbilling',
                hidden   : true
            },
            
            // {
            //     id       : 'settings',
            //     title    : 'Settings',
            //     translate: 'NAV.SETTINGS.TITLE',
            //     type     : 'item',
            //     icon     : 'settings',
            //     url      : '/settings'
            // }
            
        ]
        
    },
    {
        id       : 'setup',
        title    : 'Setup',
        translate: 'NAV.SETUP',
        type     : 'group',
        icon     : 'receipt',
        children : [
            {
                id       : 'credentials',
                title    : 'Credentials',
                translate: 'NAV.SETUPCREDENTIALS.TITLE',
                type     : 'item',
                icon     : 'security',
                url      : '/setup/credentials',
                hidden   : true
            },
            {
                id       : 'agentpools',
                title    : 'Agent Pools',
                translate: 'NAV.AGENTPOOLS.TITLE',
                type     : 'item',
                icon     : 'poll',
                url      : '/agentpools',
                hidden   : true
            },
            {
                id       : 'setupmanagedservices',
                title    : 'Managed Services',
                translate: 'NAV.SETUPMANAGEDSERVICES.TITLE',
                type     : 'item',
                icon     : 'poll',
                url      : '/setup/managedservices',
                hidden   : true
            },
            
        ]
        
    }
];
